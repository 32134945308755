import React, { Component } from 'react'
import ProjectCard from './ProjectCard.jsx'
import arrow from './assets/down.png'
import journey from './assets/journey.png'


export default class Main extends Component {
  handleSubmitContact = (e) => {
    e.preventDefault()
  }

  render() {
    return (
      <div className="main">
        <div id='home'>
          <div id="top">

            <div class="menu-wrapper">
              <header className="header">
                <a href="#home" className="logo">
                  JACKY C. YANG
              </a>
                <input class="menu-btn" type="checkbox" id="menu-btn" />
                <label class="menu-icon" for="menu-btn">
                  <span class="navicon"></span>
                </label>
                <ul class="menu">
                  <li><a href="#about" className="menu-list">About</a></li>
                  <li><a href="#se" className="menu-list">Dev</a></li>
                  <li><a href="#ux" className="menu-list">UX</a></li>
                  <li><a href="#contact" className="menu-list">Contact</a></li>
                </ul>
              </header >
            </div>

            <div id="opening" className='a-section'>
              <div id="opening-content">
                <div id="jacky">
                  Hi, Jacky here.
                </div>
                <div id="subtitle"><a target="_blank" href="https://www.linkedin.com/in/jackycyang/">
                  <img className="link-img" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.iconsdb.com%2Ficons%2Fpreview%2Fwhite%2Flinkedin-3-xxl.png&f=1&nofb=1" width="30px"></img>
                </a> New York with Love </div>
                <div id='first-intro'>
                  <div className="self-intro">
                    <div>I am a&nbsp;</div>
                    <a className="dev" href='#se'> Software Developer </a>
                    <div> &nbsp;and a&nbsp; </div>
                    <a className="uxui" href='#ux'> UX/UI Designer </a>,
                  </div>
                  <div className="self-intro">
                    plus so much more &nbsp; <a href="#about"><img className="down-arrow" src={arrow} width="16px" /></a>
                  </div>
                </div>
              </div>
            </div>

            <div id="about" className='a-section'>
              <div id="about-content">
                <div id="about-top-part">
                  <h3 id='about-me'>
                    About Me
                  </h3>
                  <div className="two-buttons">
                    <a target="_blank" href="https://www.linkedin.com/in/jackycyang/">
                      <img className="link-img" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.iconsdb.com%2Ficons%2Fpreview%2Fwhite%2Flinkedin-3-xxl.png&f=1&nofb=1" width="34px"></img>
                    </a>
                    <a target="_blank" href="https://github.com/jackyclyang">
                      <img className="link-img" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.iconsdb.com%2Ficons%2Fpreview%2Fwhite%2Fgithub-11-xxl.png&f=1&nofb=1" width="34px"></img>
                    </a>
                  </div>
                </div>
                <div className="about-paragraph">
                  <div>
                    <p>
                      As a lifelong learner, my passion in creative problem-solving derives from my hope of making better solutions for people to interact with the world around them.
                    </p>
                    <p>
                      As a graduate from Carnegie Mellon and General Assembly, I am able to leverage my strong left-brain analytical skills and right-brain creative thinking to solve problems through every stage of my life:
                    </p>
                    <img src={journey} id="journey"></img>
                  </div>
                </div>
              </div>
            </div>

            <div id="se" className='a-section'>
              <div id="se-content">
                <h3 id="se-title">Software Development Projects</h3>
                <div className="map-cards">
                  {seProjects.map(project => {
                    return <ProjectCard project={project} />
                  })}
                </div>
              </div>
            </div>

            <div id="ux" className='a-section'>
              <div id="ux-content">
                <h3 id="ux-title">UX Design Work</h3>
                <div className="map-cards">
                  {uxProjects.map(project => {
                    return <ProjectCard project={project} />
                  })}
                </div>
              </div>
            </div>
            <div id="contact" className='a-section'>
              <div id="contact-content">
                <h3>Contact Me</h3>
                <div className="section-paragraph">
                  <p>
                    Want to work together on something amazing?
                  </p>
                  <p>
                    Shoot me a message and let's connect professionally!
                  </p>
                </div>
                <a className="link-button" id="contact-button" target="_blank" href="https://forms.gle/mzmvSwJEjrT1mKX19">Get in touch</a>
              </div>
            </div>


          </div>


        </div>



      </div >
    )
  }
}

const seProjects = [
  {
    type: "se",
    name: "Nighty Night",
    description: "A full-stack app that helps you declutter your mind before bedtime",
    img: "https://res.cloudinary.com/dvmkqx6v1/image/upload/v1595848073/img_rktnqw.png",
    deploy: "http://nighty-night.surge.sh/",
    github: "https://github.com/jackyclyang/nighty_night"
  },

  {
    type: "se",
    name: "Studio Ghibli",
    description: "A showcase of the film collection from Studio Ghibli using React",
    img: "https://res.cloudinary.com/dvmkqx6v1/image/upload/v1595848253/img_ghibli_v9vasj.png",
    deploy: "https://studio-ghibli-collection.netlify.app/",
    github: "https://github.com/jackyclyang/studio-ghibli"
  },
  {
    type: "se",
    name: "Jeopardy",
    description: "The beloved game Jeopardy built with vanilla JS",
    img: "https://www.aiche.org/sites/default/files/chenected/2011/06/jeopardy.jpg",
    deploy: "https://jackyclyang.github.io/jeopardy/",
    github: "https://github.com/jackyclyang/jeopardy"
  }
]


const uxProjects = [

  {
    type: "ux",
    name: "Seamless.Ai",
    description: "A leading SaaS for sales professionals to find leads",
    img: "https://res.cloudinary.com/dvmkqx6v1/image/upload/v1595849453/22_cycbzm.jpg",
    deploy: "https://www.jackycyang.com/seamless-ai",
    github: ""
  },
  {
    type: "ux",
    name: "NYS Board of Elections",
    description: "UX case study for the government's website redesign",
    img: "https://res.cloudinary.com/dvmkqx6v1/image/upload/v1595849365/website_mockup_copy_2_izvaze.jpg",
    deploy: "https://www.jackycyang.com/new-york-state-board-of-elections",
    github: ""
  },
  {
    type: "ux",
    name: "Lyft Social",
    description: "UX case study for a conceptual feature integration",
    img: "https://res.cloudinary.com/dvmkqx6v1/image/upload/v1595849551/iPhone_copy_htn4va.jpg",
    deploy: "https://www.jackycyang.com/lyft-social",
    github: ""
  }
]