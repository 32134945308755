import React, { Component } from 'react'
import Header from './components/Header.jsx'
import Main from './components/Main.jsx'
import './App.css'

export default class App extends Component {
  render() {
    return (
      <div className='body'>
        <link href="https://fonts.googleapis.com/css2?family=Caveat&family=Delius&family=Poiret+One&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Rochester&display=swap" rel="stylesheet" />

        <Main />
      </div>
    )
  }
}

